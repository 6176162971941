import React from 'react'
import Grid from '@material-ui/core/Grid';

import Layout from "../components/layout"
import SEO from "../components/seo"

//comment

export default function PastWork() {
    return(
        <Layout>
        <SEO title="Past Work" />
        <Grid container spacing={2} class="pastwork-body-wrapper">

        <div class="about-title-wrapper">
              <div class="productions-title">
                  Past shows
              </div>
          </div>
            <div class="pastwork-content-row pastwork-row">
                <div class="pastwork-pic-block pastwork-pic-1">
                </div>
                <div class="pastwork-content-block text-block">
                    <h2 class="glowist forty-font">Beulah Creek</h2>
                    <div class="byline">
                        <p><strong>Written By:</strong> Neeley Gossett</p>
                        <p><strong>Performed:</strong> September, 2014</p>
                        <p><strong>At:</strong> Dunwoody Nature Center</p>
                    </div>

                    <p>Neeley Gossett’s Beulah Creek takes place during the summer of 1936 amidst a Baptist camp meeting in South Georgia. When the pastor’s wife Ruth meets Iris, the WPA photographer documenting the revival, an unexpected relationship develops between the two women that challenges the societal norms of the time.

A chorus of women sing Southern hymns and speak biblical text to give Ruth a means of expressing the feelings she does not know nor dare to name. When the revival participants grow suspicious of the relationship, Ruth must choose between Iris and the community in which she was raised.</p>
                </div>
            </div>

            <div class="pastwork-content-row pastwork-row">
                <div class="pastwork-pic-block pastwork-pic-2">
                </div>
                <div class="pastwork-content-block text-block">
                    <h2 class="glowist forty-font">Empty Rooms</h2>
                    <div class="byline">
                        <p><strong>Written By:</strong> Annie Harrison Elliott</p>
                        <p><strong>Performed:</strong> October, 2017</p>
                        <p><strong>At:</strong> Metro Atlanta Tech Offices</p>
                    </div>
                    <p>Empty Rooms follows Jennifer, a brilliant developer, as she prepares for a conference that could make or break the tech company she’s spent her entire career supporting. But between Sara, the stay-at-home mom re-entering the workforce; Natalie, the whip-smart but underutilized (and unpaid) Millennial intern; Frank, the coder-turned-admin in over his head; Margot, the older and wiser PR specialist called in to reverse an inevitable crisis; and one-sided convos with Ada Lovelace, the world’s first (and long-dead) computer programmer, Jennifer has her work cut out for her.
</p>
                </div>
            </div>

            <div class="pastwork-content-row pastwork-row">
                <div class="pastwork-pic-block pastwork-pic-3">
                </div>
                <div class="pastwork-content-block text-block">
                    <h2 class="glowist forty-font">Joy Luck Club</h2>
                    <div class="byline">
                        <p><strong>Written By:</strong> Annie Harrison Elliott, Daryl Fazio, Neeley Gossett, Addae Moon</p>
                        <p><strong>Performed:</strong> October, 2015</p>
                        <p><strong>At:</strong> 7 Stages' Curious (Literary) Encounters</p>
                    </div>
                    <p>June, Waverly, Rose and Lena are throwing a house party to share with guests their mothers’ favorite pastime, Mahjong. In this immersive experience inspired by Amy Tan’s “The Joy Luck Club,” three audience members at a time sat with one of the book’s characters, who taught them to play Mahjong while sharing a memory about her mother. Refreshments of green tea and butter cookies were also shared in this rotating experience that lasted approximately 30 minutes.</p>
                </div>
            </div>
        </Grid>
        </Layout>

    )
}
